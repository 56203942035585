import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Footer from "../components/Footer/footer"
import Accueil   from './section/accueil'
import APropos   from './section/a-propos'
import Beneficiaires   from './section/beneficiaires'
import Benevoles   from './section/benevoles'
import Presse   from './section/presse'
import Dons   from './section/dons'

const IndexPage = (data) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Accueil" />
        <Accueil siteTitle={data.site.siteMetadata.title} />
        <APropos/>
        <Beneficiaires/>
        <Benevoles/>
        <Presse />
        <Dons/>
        <Footer />
      </Layout>
    )}
  />
)

export default IndexPage
