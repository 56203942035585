import React from "react"

const Presse = (props) => (
  <div className="container pt-5" id="presse">
    <div className="row py-5">
      <div className="col-md-8 offset-md-2">
        <h2 className="mb-0">On parle de nous dans la Presse</h2>
        <p></p>
        <ul>
            <li>
                <a href="/pdf/presses/messager_27.09.19.jpg" target="_blank">Le Messager du 27 septembre 2019</a>
            </li>
            <li>
                <a href="/pdf/presses/le_regional_02.10.2019.jpg" target="_blank">Le Régional du 2 octobre 2019</a>
            </li>
            <li>
                <a href="/pdf/presses/le_courrier_07.11.19.jpeg" target="_blank">Le Courrier du 7 novembre 2019</a>
            </li>
            <li>
                <a href="/pdf/presses/le_courrier_04.06.20.jpg" target="_blank">Le Courrier du 4 juin 2020</a>
            </li>
            <li>
                <a href="/pdf/presses/le_courrier_18.06.20.jpeg" target="_blank">Le Courrier du 18 juin 2020</a>
            </li>
            <li>
                <a href="/pdf/presses/le_messager_19.06.2020.jpeg" target="_blank">Le Messager du 19 juin 2020</a>
            </li>
            <li>
                <a href="/pdf/presses/le_messager_28.05.2021.jpeg" target="_blank">Le Messager du 28 mai 2021</a>
            </li>
        </ul>
      </div>
    </div>
  </div>
)

export default Presse