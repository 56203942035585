import React from "react"

const Beneficiaires = (props) => (
  <div className="container pt-5" id="beneficiaires">
    <div className="row py-5">
      <div className="col-md-8 offset-md-2">
        <h2 className="mb-3">Bénéficiaires</h2>
        <p>
          Les familles ou les personnes seules reçoivent une carte distribuée
          par les services sociaux de la région d'Oron-la-Ville.
          Cette carte leur permet, en versant 1 franc, de venir chercher de la nourriture.
        </p>
        <a href="pdf/info-beneficiaires-20220908.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-3" >Fonctionnement des distributions</a>
      </div>
      <div className="col-md-10 offset-md-1 mt-5">
        <img className="img-fluid" src="img/nourriture2020.jpg" alt="" />
      </div>
    </div>
  </div>
)

export default Beneficiaires
