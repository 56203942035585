import React from "react"

const Dons = (props) => (
  <div className="container pt-2" id="dons">
    <div className="row py-3">
      <div className="col-md-8 offset-md-2">
        <h2 className="mb-3">Dons et sponsors</h2>
        <p>Nous existons grâce à la générosité de nos donateurs. Nous acceptons également les denrées alimentaires. Merci pour votre générosité! Voici des <a href="pdf/SPONSORS-alORONtaide_sept_1A_2022.pdf" target="_blank" rel="noopener noreferrer" >informations complémentaires relatives aux dons</a> (nos besoins, etc.)</p>

        <div className="bg-salmon rounded pt-4 pl-4 pr-4 my-2 mb-5">
          <div className="row mb-md-3">
            <div className="col-md-12 text-center text-md-left">
                <p className="mb-0">IBAN</p>
                <p className="h6 text-nowrap">CH76 0900 0000 1529 5041 5</p>
            </div>
          </div>
          <div className="row py-3 py-md-0">
            <div className="col-md-6">
              <div className="mb-4 text-center text-md-left">
                <p className="mb-0">Nom du compte</p>
                <strong>ALORONTAIDE</strong>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-4 text-center text-md-left">
                <p className="mb-0">Adresse</p>
                <strong>
                  Chemin du Marais 6<br />
                  1084 Carrouge
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Dons
