import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer className="bg-light pt-5" id="contact">
    <div className="container py-5">
      <div className="row">
        <div className="col-sm-6 col-lg-4 offset-lg-2 col-md-5 offset-md-1 text-center text-sm-left">
          <p className="mb-1">Adresse</p>
          <p className="font-weight-bold">
            alORONtaide<br />
            Ch. du Marais 6<br />
            1084 Carrouge
          </p>
        </div>
        <div className="col-sm-6 col-lg-4 col-md-5">
          <a href="mailto:info@alorontaide.ch" className="btn btn-primary btn-block d-flex justify-content-center">
            <img className="d-inline-block mr-2" src="svg/mail.svg" alt="" />
            info@alorontaide.ch
          </a>
          <a href="tel:079 174 58 97" className="btn btn-dark btn-block d-flex justify-content-center">
            <img className="d-inline-block mr-1" src="svg/phone.svg" alt="" />
            079 174 58 97
          </a>
          <a href="tel:079 398 03 60" className="btn btn-dark btn-block d-flex justify-content-center">
            <img className="d-inline-block mr-1" src="svg/phone.svg" alt="" />
            079 398 03 60
          </a>
        </div>
        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 pt-5 pb-4">
          <p className="text-center text-sm-left">Ils nous soutiennent</p>
          <div className="bg-white p-4 rounded">
            <div className="d-flex justify-content-around flex-wrap">
              <a href="https://gc-oron.org" target="_blank" rel="noopener noreferrer" className="partner">
                <img className="m-auto img-fluid" src="img/partners/gc-oron.png" alt="GC Oron" />
              </a>
              <a href="https://antistatique.net" target="_blank" rel="noopener noreferrer" className="partner">
                <img className="m-auto img-fluid" src="svg/partenaire-antistatique.svg" alt="" />
              </a>
              <a href="https://www.gallarotti.ch" target="_blank" rel="noopener noreferrer" className="partner">
                <img className="m-auto img-fluid" src="img/partenaire-gallarotti.png" alt="" />
              </a>
              <a href="https://www.abbox.ch" target="_blank" rel="noopener noreferrer" className="partner">
                <img className="m-auto img-fluid" src="img/partenaire-abbox.png" alt="" />
              </a>
              <span className="partner">
                <img className="m-auto img-fluid" src="img/partenaire-lacolombe.png" alt="La Colombe Sàrl, Richoz Paul" />
              </span>
              {/* new sponsors... */}
            </div>
            <div className="partner-text d-flex flex-wrap">
              <small className="partner">
                Philippe Horisberger, MAXIVUE Oron
              </small>
              <a href="https://www.oron.ch/" target="_blank" rel="noopener" className="partner">
                Commune d'Oron
              </a>
              <small className="partner">
                Commune de Montpreveyres
              </small>
              <small className="partner">
                Commune de Maracon
              </small>
              <small className="partner">
                Commune de Lutry
              </small>
              <small className="partner">
                Commune de Bourg-en-Lavaux
              </small>
              <small className="partner">
              Commune de Servion
              </small>
              <small className="partner">
                KiWANIS Club Oron
              </small>
              <a href="https://www.lachenillegourmande.ch" target="_blank" className="partner">
              La chenille gourmande, Oron
              </a>
              <small className="partner">
              Pâtisserie-Boulangerie Ronny (Mézières)
              </small>
              <a href="https://www.cartonsducoeur.ch/cb-profile/27-oron.html" target="_blank" className="partner">
              Cartons du cœur, Oron
              </a>
              <a href="https://www.grosjeanstettler.ch/" target="_blank" className="partner">
              Stettler Vucherens
              </a>
              <a href="https://www.gheza.com/" target="_blank" className="partner">
              Gheza cuisine
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-3 offset-lg-2 col-md-4 offset-md-1 text-md-left text-center py-2 text-muted small">
          Copyright © Al Oron t’aide
        </div>
        <div className="col-lg-5 col-md-6 text-md-right text-center py-2 text-muted small">
          Design et développement par <a className="text-muted link-as" rel="noopener noreferrer" target="_blank" href="https://antistatique.net">Antistatique</a>.
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
